import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-15.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-15.use[2]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-15.use[3]!../../node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!../../node_modules/import-glob-loader/index.js!./ProductDetail.vue?vue&type=style&index=0&id=4f22957d&lang=scss&scoped=true";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};