<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
import WishlistButton from "../components/Utility/WishlistButton.vue";
import { useImageZoom } from "../composables/imageZoom.js";
import { useCollapsible } from "../includes/collapsible.js";
import AddToCartButton from "../components/Utility/AddToCartButton.vue";
import QuantitySelector from "../components/Utility/QuantitySelector.vue";
import RBMImage from "../components/Utility/RBMImage.vue";
import PopularProducts from "../components/PopularProducts/PopularProducts.vue";
import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

useCollapsible();

const loading = ref(false); /* NOT NECESSARY (FROM SMARTY) */

const props = defineProps({
  product: Object,
  quotationUrl: String,
});

const QuantitySelectorValue = ref(1);

const updateQuantity = (quantity) => {
  QuantitySelectorValue.value = quantity;
};

const loadAll = ref(false);

const optionsOpen = ref(false);

const { zoomed, zoomContainer, originalImage } = useImageZoom();
const productDetailDetailsText = ref(productDetailDetailsTextCode);
const productDetailImagesText = ref(productDetailImagesTextCode);
const productDetailInStockText = ref(productDetailInStockTextCode);
const productDetailoutOfStockText = props.product.delivery_time.out_of_stock;
const productDetailInformationText = ref(productDetailInformationTextCode);
const productDetailShippingText = props.product.delivery_time.in_stock;
const productDetailSpecsText = ref(productDetailSpecsTextCode);
const productDetailDownloadsText = ref(productDetailDownloadsTextCode);
const productDetailProductDescriptionTitle = ref(
  productDetailProductDescriptionTextCode
);
const productDetailProductSpecificationsText = ref(
  productDetailProductSpecificationsTextCode
);

const [first] = props.product.images;

const mainImage = ref(first);

// const dialog = ref(null);

const changeMainImage = (image) => {
  mainImage.value = image;
  useImageZoom(image);
};

const filteredSpecifications = computed(() => {
  let grade = null;
  const filteredSpecs = props.product.specifications.filter((spec) => {
    if (spec.label === "Grade") {
      grade = spec;
      return false;
    }
    return true;
  });
  return { filteredSpecs, grade };
});
const isDivOutOfView = ref(false);

const checkDivVisibility = () => {
  const div = document.querySelector(".product-header");
  const rect = div.getBoundingClientRect();
  if (rect.bottom < 0 || rect.top > window.innerHeight) {
    isDivOutOfView.value = true;
  } else {
    isDivOutOfView.value = false;
  }
};

const checkActiveVariant = () => {
  const variants = props.product.variants;
  if(variants === undefined) return [];
  const activeVariants = variants.map((variant) => {
    const active = variant.items.find((item) => item.active);
    return active.value;
  });
  return activeVariants;
};

const activeVariants = checkActiveVariant();

const handleClickOutsideDropdown = (event) => {
  const dropdown = document.querySelector(".product-options-dropdown");
  if (dropdown && !dropdown.contains(event.target)) {
    optionsOpen.value = false;
  }
};

onMounted(() => {
  window.addEventListener("resize", useImageZoom);
  window.addEventListener("click", handleClickOutsideDropdown);
  window.addEventListener("scroll", checkDivVisibility);
});

onUnmounted(() => {
  window.removeEventListener("resize", useImageZoom);
  window.removeEventListener("click", handleClickOutsideDropdown);
  window.removeEventListener("scroll", checkDivVisibility);
});
</script>

<template>
  <bread-crumb
    v-if="product.active"
    pageModifier="mob-last-child"
    :crumb="product.breadcrumb"
  ></bread-crumb>
  <div v-if="product.active">
    <section class="product">
      <div
        class="product-sticky-bar"
        :class="{ 'product-sticky-bar--active': isDivOutOfView }"
      >
        <div class="product-sticky-bar__container">
          <div
            class="product-sticky__image"
            v-if="product.main_image && !loading"
          >
            <RBMImage :image="product.main_image" />
          </div>
          <div
            class="product-sticky__image product-sticky__image--skeleton"
            v-else-if="product.main_image && loading"
          >
            <!-- -->
          </div>
          <div>
            <span class="product-sticky__title notranslate">{{
              product.title
            }}</span>
            <span class="product-in-stock" v-if="product.amount_in_stock > 5">
              <i class="fa-solid fa-circle-check"></i>
              {{ product.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
              <span>{{ product.delivery_time.in_stock }}</span>
            </span>
            <span
              class="product-in-stock product-in-stock--low"
              v-else-if="product.amount_in_stock > 0"
            >
              <i class="fa-solid fa-circle-check"></i>
              {{ product.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
              <span>{{ product.delivery_time.in_stock }}</span>
            </span>
            <span class="product-in-stock product-in-stock--out" v-else>
              {{ product.delivery_time.out_of_stock }}
            </span>
          </div>
          <div class="product-sticky-bar__cart">
            <span class="product-price" v-if="product.show_price">
              {{ $filters.toCurrency(product.actual_price) }}
              <span
                >excl. btw ({{ $filters.toCurrency(product.price_with_tax) }} incl. btw)</span
              >
              <!-- TODO: HARDCODED TEXT-->
            </span>
            <span class="product-price" v-else> Prijs op aanvraag </span>
            <div class="product-cart">
              <div class="product-cart__button">
                <AddToCartButton :product="product" v-if="product.show_price" />
                <a :href="appendLanguagePrefixURL(`${props.quotationUrl}?id=${product.external_id}`)" class="btn-solid--primary-600" v-else>
                  Vraag een offerte aan
                </a>
              </div>
              <WishlistButton :item="product" />
            </div>
          </div>
        </div>
      </div>

      <header class="product-header" aria-labelledby="product-header__title">
        <div class="product-header__container">
          <span class="product-header__title">
            <h1 id="product-header__title" class="notranslate">
              {{ product.title }}
            </h1>
          </span>
          <section
            class="product-header-images"
            :aria-label="productDetailImagesText"
          >
            <div
              class="product-header-images__thumbnails"
              v-if="product.images.length > 1 && !loading"
            >
              <div
                class="product-header-images__thumbnail"
                :class="{
                  'product-header-images__thumbnail--active':
                    mainImage.default === image.default,
                }"
                v-for="image in product.images"
                @click="changeMainImage(image)"
              >
                <RBMImage :image="image" />
              </div>
            </div>
            <div
              class="product-header-images__thumbnails"
              v-else-if="product.images.length > 1 && loading"
            >
              <div
                class="product-header-images__thumbnail product-header-images__thumbnail--skeleton"
                v-for="image in product.images"
              ></div>
            </div>
            <div class="product-header-images__main" v-if="!loading">
              <WishlistButton :item="product" />
              <div class="image-zoom-container">
                <div
                  ref="originalImage"
                  class="product-header-images__main-image"
                >
                  <RBMImage :image="mainImage" />
                </div>
                <div ref="zoomContainer" class="zoom-container"></div>
              </div>
            </div>
            <div
              class="product-header-images__main product-header-images__main--skeleton"
              v-else
            >
              <!-- -->
            </div>
          </section>
          <section
            class="product-header-content"
            :aria-label="productDetailDetailsText"
            v-if="!loading"
          >
            <div
              class="product-header-content__text"
              v-if="product.short_description"
            >
              <span class="notranslate">
                {{ product.short_description.substring(0, 108) + ".... " }}
              </span>
              <button data-target="product-information__text">lees meer</button>
              <!-- TODO: HARDCODED TEXT-->
            </div>
            <div
              class="product-header-content__text"
              v-else-if="product.long_description"
            >
              <span class="notranslate">
                {{ product.long_description.substring(0, 108) + ".... " }}
              </span>
              <button data-target="product-information__text">lees meer</button>
              <!-- TODO: HARDCODED TEXT-->
            </div>

            <div
              class="product-options"
              v-if="product.variants !== null"
              v-for="(variant, index) in product.variants"
            >
              <span class="product-options__title"> {{ variant.label }}: </span>
              <div class="product-options-dropdown">
                <span
                  class="product-options__current"
                  @click.prevent="optionsOpen = !optionsOpen"
                >
                  {{ activeVariants[index] }}
                  <i class="fa-regular fa-angle-down"></i
                ></span>
                <div
                  class="product-options__items"
                  :class="{ 'product-options__items--active': optionsOpen }"
                  v-if="!loadAll"
                >
                  <a
                    class="product-options__item"
                    :class="{ 'product-options__item--active': option.active }"
                    v-for="option in variant.items.slice(0, 10)"
                    :key="option.value"
                    :href="option.url"
                  >
                    {{ option.value }}
                  </a>
                  <a
                    class="product-options__item product-options__item--hidden"
                    :class="{ 'product-options__item--active': option.active }"
                    v-for="option in variant.items.slice(10, 99)"
                    :key="option"
                    :href="option.url"
                  >
                    {{ option.value }}
                  </a>
                  <button
                    class="product-options__item--button"
                    @click.prevent="loadAll = !loadAll"
                    v-if="variant.items.length > 10"
                  >
                    Toon meer
                  </button>
                  <!-- TODO: HARDCODED TEXT-->
                </div>
                <div class="product-options__items" v-else>
                  <a
                    class="product-options__item"
                    :class="{ 'product-options__item--active': option.active }"
                    v-for="option in variant.items"
                    :key="option"
                    :href="option.url"
                  >
                    {{ option.value }}
                  </a>
                  <button
                    class="product-options__item--button"
                    @click.prevent="loadAll = !loadAll"
                  >
                    Toon minder
                  </button>
                  <!-- TODO: HARDCODED TEXT-->
                </div>
              </div>
              <div
                class="product-options__grade"
                v-if="filteredSpecifications.grade !== null"
              >
                <div class="grade-item">
                  <span class="label"
                    >{{ filteredSpecifications.grade.label }}:&nbsp;</span
                  >
                  <span class="grade">{{
                    filteredSpecifications.grade.value
                  }}</span>
                  <span class="grade-icon"
                    ><i class="fa-solid fa-info"></i
                  ></span>
                </div>
              </div>
            </div>

            <span class="product-price" v-if="product.show_price">
              {{ $filters.toCurrency(product.actual_price) }}
              <span
                >excl. btw ({{ $filters.toCurrency(product.price_with_tax )}} incl. btw)</span
              >
              <!-- TODO: HARDCODED TEXT-->
            </span>
            <span class="product-price" v-else> Prijs op aanvraag </span>

            <span class="product-in-stock" v-if="product.amount_in_stock > 5">
              <i class="fa-solid fa-circle-check"></i>
              {{ product.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
              <span>{{ product.delivery_time.in_stock }}</span>
            </span>
            <span
              class="product-in-stock product-in-stock--low"
              v-else-if="product.amount_in_stock > 0"
            >
              <i class="fa-solid fa-circle-check"></i>
              {{ product.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
              <span>{{ product.delivery_time.in_stock }}</span>
            </span>
            <span class="product-in-stock product-in-stock--out" v-else>
              {{ product.delivery_time.out_of_stock }}
            </span>

            <div class="product-cart">
              <QuantitySelector
                @updated-quantity="updateQuantity"
                v-if="product.show_price"
              />
              <AddToCartButton
                :product="product"
                :quantity="QuantitySelectorValue"
                v-if="product.show_price"
                :disabled="QuantitySelectorValue < 1 || QuantitySelectorValue == null"
              />
              <a class="btn-solid--primary-600" :href="appendLanguagePrefixURL(`${props.quotationUrl}?id=${product.external_id}`)" v-else-if="!product.show_price" >
                  Vraag een offerte aan
              </a>
              <WishlistButton :item="product" />
            </div>

            <div class="product-usps" v-if="product.usp">
              <div class="product-usp" v-for="usp in product.usp">
                <i class="fa-regular fa-check"></i> {{ usp }}
              </div>
            </div>
          </section>
          <section
            class="product-header-content"
            :aria-label="productDetailDetailsText"
            v-else
          >
            <div class="product-header-content__text--skeleton">
              <!-- -->
            </div>

            <div
              class="product-options"
              v-if="product.variants !== null"
              v-for="(variant, index) in product.variants"
            >
              <span class="product-options__title--skeleton">
                <!-- -->
              </span>
              <div class="product-options-dropdown">
                <span
                  class="product-options__current--skeleton"
                  @click.prevent="optionsOpen = !optionsOpen"
                >
                  <!-- -->
                </span>
              </div>
              <div
                class="product-options__grade"
                v-if="filteredSpecifications.grade !== null"
              >
                <div class="grade-item">
                  <span class="label"
                    >{{ filteredSpecifications.grade.label }}:&nbsp;</span
                  >
                  <span class="grade">{{
                    filteredSpecifications.grade.value
                  }}</span>
                  <span class="grade-icon"
                    ><i class="fa-solid fa-info"></i
                  ></span>
                </div>
              </div>
            </div>

            <span class="product-price--skeleton">
              <!-- -->
            </span>

            <span class="product-in-stock--skeleton">
              <!-- -->
            </span>

            <div class="product-cart--skeleton">
              <span class="product-cart-quanity--skeleton">
                <!-- -->
              </span>
              <span class="product-cart-button--skeleton">
                <!-- -->
              </span>
              <span class="product-cart-heart--skeleton">
                <!-- -->
              </span>
            </div>

            <div class="product-usps" v-if="product.usp">
              <div class="product-usp--skeleton" v-for="usp in product.usp">
                <!-- -->
              </div>
            </div>
          </section>
        </div>
      </header>

      <div class="product-information">
        <div class="product-information__container">
          <div class="product-information__primary">
            <section
              class="product-information__text"
              id="product-information__text"
              :aria-label="productDetailInformationText"
              v-if="product.long_description"
            >
              <span class="product-information__title">
                {{ productDetailProductDescriptionTitle }}
              </span>
              <p class="notranslate">
                {{ product.long_description }}
              </p>
            </section>
            <section
              class="product-information__specs"
              :aria-label="productDetailSpecsText"
              v-if="filteredSpecifications.filteredSpecs.length > 0"
            >
              <span class="product-information__title">
                {{ productDetailProductSpecificationsText }}
              </span>
              <div class="product-information__specs-table">
                <div
                  class="product-information__row"
                  :class="{ notranslate: spec.label === 'Itemnaam' }"
                  v-for="spec in filteredSpecifications.filteredSpecs"
                  :key="spec"
                >
                  <span class="product-spec__label">{{ spec.label }}</span>
                  <span class="product-spec__text">{{
                    Array.isArray(spec.value)
                      ? spec.value.join(", ")
                      : spec.value
                  }}</span>
                </div>
              </div>
            </section>
          </div>
          <aside
            class="product-information__secondary"
            v-if="product?.datasheets?.length > 0 || product?.manuals?.length > 0"
          >
            <section
              class="product-downloads"
              :aria-label="productDetailDownloadsText"
            >
              <div class="product-downloads__downloads">
                <article
                  class="collapsible__item product-downloads__row"
                  v-if="product.datasheets.length > 0"
                >
                  <div class="collapsible__item-title product-downloads__title">
                    <button
                      aria-expanded="false"
                      aria-controls="collapsible__item-content--datasheets"
                      id="collapsible__item-button--datasheets"
                    >
                      Datasheets
                      <!-- TODO: HARDCODED TEXT-->
                    </button>
                  </div>
                  <section
                    class="collapsible__item-content"
                    id="collapsible__item-content--datasheets"
                    aria-labelledby="collapsible__item-button--datasheets"
                  >
                    <div class="collapsible__item-text">
                      <div class="product-downloads__downloads">
                        <div
                          class="product-downloads__download notranslate"
                          v-for="datasheet in product.datasheets"
                          :key="datasheet"
                        >
                          {{ datasheet.name }}
                          <i class="fa-light fa-download"></i>
                          <a
                            download
                            class="block-link"
                            :href="appendLanguagePrefixURL(datasheet.url)"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                    </div>
                  </section>
                </article>
                <article
                  class="collapsible__item product-downloads__row"
                  v-if="product.manuals.length > 0"
                >
                  <div class="collapsible__item-title product-downloads__title">
                    <button
                      aria-expanded="false"
                      aria-controls="collapsible__item-content--manuals"
                      id="collapsible__item-button--manuals"
                    >
                      Montagehandleiding
                      <!-- TODO: HARDCODED TEXT-->
                    </button>
                  </div>
                  <section
                    class="collapsible__item-content"
                    id="collapsible__item-content--manuals"
                    aria-labelledby="collapsible__item-button--manuals"
                  >
                    <div class="collapsible__item-text">
                      <div class="product-downloads__downloads">
                        <div
                          class="product-downloads__download notranslate"
                          v-for="manual in product.manuals"
                          :key="manual"
                        >
                          {{ manual.name }} <i class="fa-light fa-download"></i>
                          <a
                            download
                            class="block-link"
                            :href="manual.url"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                    </div>
                  </section>
                </article>
              </div>
            </section>
          </aside>
        </div>
      </div>
      <PopularProducts
        :products="product.related"
        title="Andere interessante producten"
        :maxItems="4"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "../../sass/elements/skeletons.scss";

.wishlist__button {
  @apply h-[54px] w-[54px];
}

.image-zoom-container {
  @apply relative;
}

.original-image {
  cursor: zoom-in;
  width: 100%;
  height: auto;
}

.zoom-container {
  @apply transition-opacity duration-300 bg-white absolute top-0 left-0 opacity-0 bg-no-repeat bg-center pointer-events-none;
}

.product-header {
  @apply text-dark;

  &:has(+ .product-information) {
    @apply mb-24;
    @apply sm:mb-6;
  }
}

.product-header__container {
  @apply container grid grid-cols-12 gap-x-9 gap-y-10;
  @apply lg:px-6;
  @apply md:gap-6;
  @apply sm:px-4 sm:pt-[8px];
  @apply xs:gap-y-4;
}

.product-header__title {
  @apply col-span-12;

  h1 {
    @apply text-32_32_20_20 font-fontText;
  }
}

.product-header-images {
  @apply grid grid-cols-6 gap-6 col-span-6;
  @apply sm:col-span-12;

  &:not(:has(.product-header-images__thumbnails)) {
    .product-header-images__main {
      @apply col-span-6;
    }
  }
}

.product-header-images__thumbnails {
  @apply flex flex-col col-span-1 gap-[5px] flex-wrap;
  @apply xs:col-span-6 xs:flex-row xs:order-2;
}

.product-header-images__thumbnail {
  @apply flex-[0_0_54px] w-[54px] h-[54px] p-1.5 rounded border border-border transition-all duration-300 cursor-pointer;
  @apply hover:border-primary;

  picture {
    @apply flex h-full w-full;
  }

  img {
    @apply object-contain;
  }

  &--active {
    @apply border-primary-500;
  }
}

.product-header-images__main {
  @apply col-span-5 h-full w-full relative overflow-hidden;
  @apply xs:col-span-6 xs:order-1;

  .wishlist__button {
    @apply hidden;
    @apply sm:flex sm:ml-auto sm:h-[40px] sm:w-[40px] sm:absolute sm:right-0 sm:top-0 sm:z-[100];
  }
}

.product-header-images__main-image {
  @apply flex items-center justify-center h-full w-full;

  figure {
    @apply m-0;
  }
}

.product-header-content {
  @apply col-span-6 text-18_28_14_22 pr-14;
  @apply sm:col-span-12 sm:pr-0;
}

.product-header-content__text {
  button {
    @apply text-primary-600;
    @apply hover:text-primary-800;
  }
}

.product-options-dropdown {
  @apply relative w-full;
}

.product-options {
  @apply mt-4 relative;
  @apply sm:flex sm:items-center sm:gap-5;
}

.product-options__title {
  @apply flex text-17_28_14_20 font-bold;
}

.product-options__items {
  @apply flex flex-wrap gap-2 relative;
}

.product-options__current,
.product-options__item {
  @apply text-16_28_14_22 px-6 py-1 rounded border border-border transition-all duration-300 cursor-pointer bg-white;
  @apply hover:bg-[#F2F2F0] hover:border-[#F2F2F0];

  &--active {
    @apply bg-[#FAF2E6] border-primary-500 font-bold;
  }

  &--button {
    @apply text-primary-600 text-16_28_14_22 px-2 py-1;
    @apply hover:text-primary-800;
  }

  &--hidden {
    @apply hidden;
  }
}

.product-options__current {
  @apply items-center max-w-[368px] w-full min-h-[48px] px-4 justify-between hidden;
  @apply md:flex;

  i {
    @apply text-[16px] text-black transition-all duration-300;
  }

  &:has(+ .product-options__items--active) {
    i {
      @apply rotate-180;
    }
  }
}

.grade-item {
  @apply rounded-sm bg-primary text-white inline-flex font-fontText pl-3 pr-9 text-[12px] font-bold relative;

  .grade-icon {
    @apply bg-white rounded-sm w-5 h-5 flex items-center justify-center ml-auto absolute right-[3px] top-1/2 -translate-y-1/2;
    i {
      @apply text-black;
    }
  }
}

@media screen and (max-width: 900px) {
  .product-options__items {
    @apply absolute top-full gap-0 shadow-dropdownBox rounded z-[99] max-h-[265px] overflow-scroll max-w-[368px] opacity-0 pointer-events-none transition-opacity duration-300;

    &.product-options__items--active {
      @apply opacity-100 pointer-events-auto;
    }
  }

  .product-options__item {
    @apply border-0 border-b w-full min-h-[38px] rounded-none items-center flex px-4;

    &.product-options__item--hidden {
      @apply flex;
    }

    &:has(+ .product-options__item--active) {
      @apply border-b-primary-500;
    }

    &:has(+ .product-options__item--active:hover) {
      @apply border-b-[#F2F2F0];
    }
  }

  .product-options__item--button {
    @apply hidden;
  }
}

.product-price {
  @apply flex flex-wrap text-24_28_18_26 font-bold items-end gap-1 mt-8;
  @apply xs:mt-4;

  span {
    @apply text-14_21_12_21 font-normal;
  }
}

.product-in-stock {
  @apply flex flex-wrap text-17_28_14_22 text-green font-bold items-center gap-x-1 mt-8;
  @apply xs:mt-4;

  span {
    @apply text-14_21_12_21 font-normal text-dark;
  }

  &.product-in-stock--low {
    @apply text-[#DE8C00];
  }

  &.product-in-stock--out {
    @apply text-[#666666];
  }
}

.product-cart {
  @apply flex items-center mt-3 gap-2.5;
  @apply lg:fixed lg:bottom-0 lg:inset-x-0 lg:justify-center lg:shadow-swiperButtons lg:px-[18px] lg:py-3 lg:bg-white lg:z-[100];
  @apply sm:gap-1.5;
  @apply xs:gap-1;

  &:has(.wishlist__button) {
    .wishlist__button {
      @apply sm:hidden;
    }
  }
}

.product-usps {
  @apply flex flex-wrap gap-x-[30px] gap-y-3 mt-6;
  @apply xs:gap-x-6;
}

.product-usp {
  @apply text-[14px] leading-[21px] inline-flex gap-2 items-center;

  i {
    @apply text-green;
  }
}

.product-information {
  @apply pb-6;
}

.product-information__container {
  @apply container grid grid-cols-12 gap-x-6;
  @apply lg:px-6;
  @apply md:gap-y-6;
  @apply sm:px-4;
}

.product-information__primary {
  @apply col-span-8;
  @apply md:col-span-12;
}

.product-information__secondary {
  @apply col-span-4 pl-3;
  @apply md:col-span-12 md:pl-0;
}

#product-information__text {
  @apply relative;
}

.product-information__text {
  @apply flex flex-col pr-7;
  @apply md:pr-0;

  h2 + p {
    @apply mt-0.5;
  }

  p {
    @apply text-17_28_14_22;
  }

  &:has(+ .product-information__specs) {
    @apply mb-9;
    @apply sm:mb-6;
  }
}

.product-information__title {
  @apply font-fontTitle text-20_26_14_22 flex font-bold;
}

.product-information__specs {
  .product-information__title {
    @apply text-20_26_15_20;
  }
}

.product-information__specs-table {
  @apply mt-3;
  @apply sm:-mx-4;
}

.product-information__row {
  @apply flex gap-6 text-17_21_14_21;

  &:nth-child(odd) {
    @apply bg-lightGrey;
  }
}

.product-spec__label {
  @apply font-bold flex-[0_0_195px] p-[14px] inline-flex items-center text-text text-17_21_14_21;
  @apply sm:px-4 sm:py-2.5 sm:flex-[0_0_160px];
}

.product-spec__text {
  @apply p-[14px] inline-flex items-center text-text text-17_21_14_21;
  @apply sm:px-4 sm:py-2.5;
}

.product-downloads {
  @apply col-span-4;
}

.product-downloads {
  @apply flex flex-col;
}

.product-downloads__row {
  @apply w-full;

  &:first-child {
    @apply border-t border-border border-solid;
  }
}

.product-downloads__title {
  @apply text-18_28_14_20 font-bold text-black min-h-[59px] flex items-center transition-all duration-300 cursor-pointer border-b border-border border-solid font-fontTitle;
  @apply hover:text-primary;
  @apply xs:min-h-[44px];
  > button {
    &::after {
      @apply relative content-["\f107"] font-fontAwesome font-normal ml-auto text-18_28_14_20;
    }
  }
}

.product-downloads__downloads {
  @apply flex flex-wrap;
}

.product-downloads__download {
  @apply flex gap-6 p-3 relative items-center transition-all duration-300 cursor-pointer w-full text-16_24_14_18 min-h-[59px];
  @apply hover:text-primary;
  @apply xs:min-h-[44px];

  &:nth-child(odd) {
    @apply bg-lightGrey;
  }

  i {
    @apply ml-auto mr-2 text-primary;
  }
}

.product-sticky-bar {
  @apply h-[83px] shadow-fixedBar fixed top-[130px] inset-x-0 bg-white z-50 opacity-0 pointer-events-none transition-all duration-300;
  @apply lg:hidden;

  .product-sticky__title {
    @apply text-17_22_14_22 font-bold;
  }

  .product-in-stock {
    @apply mt-0 text-14_21_12_21;

    span {
      @apply text-13_18_11_14;
    }
  }

  .product-price {
    @apply mt-0 text-17_21_14_21;

    span {
      @apply text-14_21_12_21;
    }
  }

  .product-cart {
    @apply mt-0;
  }

  .btn-solid--primary-600 {
    @apply px-4 text-17_21_14_21;

    i {
      @apply text-17_21_14_21;
    }
  }
}

.product-sticky-bar--active {
  @apply opacity-100 pointer-events-auto;
}

.product-sticky-bar__container {
  @apply container flex items-center h-full gap-6;
  @apply lg:px-6;
  @apply sm:px-4;
}

.product-sticky-bar__cart {
  @apply ml-auto flex items-center gap-7;
  @apply xs:gap-6;
}

.product-sticky__image {
  @apply h-[52px] w-[52px];
}

.product-sticky__image--skeleton {
  @extend .skeleton;
}

.product-header-images__main--skeleton {
  @apply aspect-square pointer-events-none h-auto;
  @extend .skeleton;
}

.product-header-images__thumbnail--skeleton {
  @apply aspect-square pointer-events-none;
  @extend .skeleton;
}

.product-header-content__text--skeleton {
  @apply h-[50px] w-[100%] mb-4 inline-flex max-w-full;
  @apply sm:mb-0;
  @extend .skeleton;
}

.product-options__title--skeleton {
  @apply h-[22px] w-[165px] mb-1.5 inline-flex max-w-full;
  @extend .skeleton;
  @apply sm:mb-0;
}

.product-price--skeleton {
  @apply h-[24px] w-[217px] mt-7 mb-10 inline-flex max-w-full;
  @apply sm:my-3;
  @extend .skeleton;
}

.product-in-stock--skeleton {
  @apply h-[19px] w-[460px] mb-1.5 inline-flex max-w-full;
  @extend .skeleton;
}

.product-cart--skeleton {
  @apply flex gap-3;
  @apply sm:hidden;
}

.product-cart-quanity--skeleton {
  @apply h-[54px] w-[145px] mb-4 inline-flex max-w-full;
  @extend .skeleton;
}

.product-cart-button--skeleton {
  @apply h-[54px] w-[294px] mb-1.5 inline-flex max-w-full;
  @extend .skeleton;
}

.product-cart-heart--skeleton {
  @apply h-[54px] w-[54px] aspect-square rounded-full mb-1.5 inline-flex max-w-full;
  @extend .skeleton;
}

.product-usp--skeleton {
  @apply h-[22px] w-[145px] mb-1.5 inline-flex max-w-full;
  @apply sm:mb-0;
  @extend .skeleton;
}

.product-options__item--skeleton {
  @apply h-[37px] w-[74px] rounded;
  @extend .skeleton;
}

.product-options__current--skeleton {
  @apply h-[40px] w-full rounded inline-flex;
  @extend .skeleton;
}

.product-cart__button {
  @apply transition-all duration-300;

  &[disabled=true] {
    @apply opacity-50 pointer-events-none cursor-not-allowed;
  }
}
</style>

<style lang="scss">
</style>
