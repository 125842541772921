<template>
    <section class="slider slider--popular-products" v-if="products && products.length != 0">
        <div class="slider__container">
            <span class="popular-products__title">{{props.title}}</span>
            <div class="slider__items">
                <div class="slider__swiper swiper swiper--buttons">
                    <div class="slider__swiper-wrapper swiper-wrapper">
                        <ProductCard
                            :isPopular="true"
                            @openDialog="openDialog()"
                            v-for="product in getRandomProducts"
                            :product="product"
                            :showSkeleton="props.showSkeleton"
                            v-bind:key="product.id"
                            :lazyImage="true"
                        />
                        <!-- Add product property to openDialog() to send real data through currently hardcoded -->
                    </div>
                    <div class="slider__buttons swiper__buttons">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
            <a :href="appendLanguagePrefixURL(webshopSlug)" class="btn-text--primary-500 btn-text--arrow-left popular-products__link" v-if="props.showLink"> <!-- TODO: Hardcoded LINK -->
                <!-- TODO: Hardcoded -->
                Bekijk alle producten
            </a>
        </div>
    </section>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import ProductCard from '../ProductCard.vue';
    import AddToCartDialog from "../Utility/AddToCartDialog.vue";
	import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

    const props = defineProps({
        title: String,
        showLink: Boolean,
        products: Array,
        maxItems: Number,
        showSkeleton: {
            type: Boolean,
            default: false
        }
    });

    const webshopSlug = ref(webshopSlugRef);
    const getRandomProducts = computed(() => {
        if (props.maxItems && props.products.length > props.maxItems) {
            const randomProducts = [];
            const productIndices = [];
            while (productIndices.length < props.maxItems) {
                const randomIndex = Math.floor(Math.random() * props.products.length);
                if (!productIndices.includes(randomIndex)) {
                    productIndices.push(randomIndex);
                    randomProducts.push(props.products[randomIndex]);
                }
            }
            return randomProducts;
        }
        return props.products;
    });

    const openDialog = () => {
        let dialog = document.querySelector('.slider--popular-products dialog');
        dialog.showModal();
    }
</script>

<style lang="scss" scoped>
    .popular-products__container {
        @apply container;
        @apply lg:px-6;
        @apply sm:px-4;
    }

    .popular-products__title {
        @apply font-fontTitle font-bold text-20_28_15_21 text-dark col-span-full;
    }

    .popular-products__link {
        @apply col-span-full text-16_24_14_18 font-normal;
        @apply sm:hidden;
    }
</style>
